@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Open+Sans:wght@400;700&display=swap');

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
}

.primary {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    max-width: 80vw;
    margin: 5px;
}

.left {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45%;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 55%;
}

.headline, .tag, .title, .tagline {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 80px;
    color: hsl(0, 0%, 20%);
    margin: 10px;
}

.headline {
    line-height: 1;
    margin-bottom: 25px;
}

.tag {
    line-height: 1;
    font-size: 30px;
    margin-bottom: 10px;
    min-width: 250px;
}

.tagline {
    font-size: 16px;
    font-weight: 400;
    max-width: 400px;
    min-width: 250px;
    line-height: 1.5;
    margin-bottom: 15px;
}

.title {
    font-size: 20px;
}


.languageBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 620px;
}

.contactBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.iconBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid hsl(0, 0%, 90%);
    border-radius: 8px;
    margin: 10px;
}

.links {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    padding: 5px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    color: hsl(0, 0%, 40%);
}

.links:hover {
    color: hsl(0, 0%, 15%);
}

.iconText {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    color: hsl(0, 0%, 40%);
}


.iconImg {
    width: 65px;
    height: 65px;
    margin-bottom: 5px;
}

.contactImg {
    width: 45px;
    height: 45px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 1100px) {
    .primary {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .left {
        align-items: center;
        text-align: center;
    }
    .headline {
        font-size: 60px;
    }
    .tag {
        font-size: 22px;
    }
}

@media only screen and (max-width: 880px) {
    .headline {
        font-size: 32px;
    }
    .tag {
        font-size: 18px;
    }
    .title {
        font-size: 16px;
        text-align: center;
    }
    .tagline {
        font-size: 13px;
    }
    .iconBox {
        margin: 5px;
        padding: 5px;
    }
    .iconImg {
        height: 50px;
        width: 50px;
    }
    .iconText {
        display: none;
    }
}