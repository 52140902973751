@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Open+Sans:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App_container__3BLxw {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.App_main__35sVR {
    width: 100vw;
}

.App_routes__3mrf_ {
    position: absolute;
    z-index: 3;
    top: 110px;
}

.App_overlay__3mCx7 {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 100%, 0.85);
}

.App_background__2geYi {
    -webkit-filter: blur(2px);
            filter: blur(2px);
    min-height: 100%;
    min-width: 1024px;
    height: auto;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
}

@media only screen and (max-width: 880px) {
    .App_routes__3mrf_ {
        top: 80px;
    }
}
.NavBar_container__2lRv0 {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    background-color: hsl(0, 0%, 100%);
    height: 70px;
    padding: 10px;
    border-bottom: 1px solid hsl(0, 0%, 70%);
    box-shadow: 0 1px 5px hsla(0, 0%, 70%, 0.5);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.NavBar_left__W7dLQ, .NavBar_right__3itSA {
    width: 50%;
    max-width: 650px;
}

.NavBar_right__3itSA {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
}

.NavBar_logo__1XcXt {
    height: 70px;
    width: 70px;
    margin-left: 10px;
}

.NavBar_navLink__1slMP {
    text-decoration: none;
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 22px;
    margin: 10px;
    color: hsl(0, 0%, 40%);
    border-bottom: 2px solid hsl(0, 0%, 100%);
}

.NavBar_navLink__1slMP:hover {
    color: hsl(0, 0%, 10%);
}

.NavBar_activeLink__3e035 {
    color: hsl(0, 0%, 10%);
    border-bottom: 2px solid hsl(0, 0%, 10%);
}

@media only screen and (max-width: 880px) {
    .NavBar_container__2lRv0 {
        height: 40px;
    }
    .NavBar_logo__1XcXt {
        height: 40px;
        width: 40px;
    }
    .NavBar_navLink__1slMP {
        font-size: 16px;
    }
}
.Projects_container__VXSxa {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    margin-bottom: 30px;
}

.Projects_headline__2Imds {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
}

.Projects_projectBox__PQ606 {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80vw;
}

@media only screen and (max-width: 880px) {
    .Projects_headline__2Imds {
        font-size: 16px;
    }
    .Projects_container__VXSxa {
        margin-bottom: 15px;
    }
}
.ProjectItem_projectItem__2uGZc {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid hsl(0, 0%, 80%);
    box-shadow: 0 1px 5px hsla(0, 0%, 70%, 0.5);
    background-color: hsl(0, 0%, 100%);
    font-family: 'Open Sans';
    margin: 20px;
}

.ProjectItem_projectHeader__2opVF {
    background-color: hsl(0, 0%, 100%);
    max-width: 800px;
}

.ProjectItem_projectFooter__1Twb6 {
    display: flex;
    flex-direction: row;
    background-color: hsl(0, 0%, 100%);
    max-width: 800px;
}

.ProjectItem_projectImage__QIXTS {
    object-fit: contain;
    width: 100%;
    border-bottom: 1px solid hsl(0, 0%, 60%);
}

.ProjectItem_projectText__3C-ym {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    padding-left: 25px;
}

.ProjectItem_projectLinksBox__iTypD {
    width: 30%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ProjectItem_projectLinks__3UPgp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ProjectItem_links__1PpD8 {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    padding: 5px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    color: hsl(0, 0%, 40%);
}

.ProjectItem_links__1PpD8:hover {
    color: hsl(0, 0%, 15%);
}

.ProjectItem_contactImg__1Jnph {
    width: 45px;
    height: 45px;
    margin-bottom: 5px;
}

.ProjectItem_title__1J-fs, .ProjectItem_mobileTitle__3J7V7 {
    font-size: 18px;
    font-weight: 700;
    color: hsl(0, 0%, 15%);
    text-transform: uppercase;
    margin-bottom: 15px;
}

.ProjectItem_mobileTitle__3J7V7 {
    opacity: 0;
}

.ProjectItem_description__3Vsws, .ProjectItem_technology__lWOLp {
    font-size: 14px;
    font-weight: 400;
    color: hsl(0, 0%, 30%);
    margin-bottom: 10px;
}

.ProjectItem_technology__lWOLp {
    font-weight: 700;
}

.ProjectItem_gaLink__3-yP4 {
    color: hsl(0, 0%, 30%);
}

.ProjectItem_gaLink__3-yP4:hover {
    color: hsl(0, 0%, 10%);  
}

@media only screen and (max-width: 880px) {
    .ProjectItem_projectFooter__1Twb6 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ProjectItem_projectText__3C-ym {
        display: none;
    }
    .ProjectItem_mobileTitle__3J7V7 {
        font-size: 14px;
        opacity: 1;
    }
    .ProjectItem_projectLinksBox__iTypD {
        width: 100%;
        padding: 2px;
    }
    .ProjectItem_links__1PpD8 {
        font-size: 12px;
        font-weight: 700;
    }
    .ProjectItem_projectItem__2uGZc {
        margin: 10px;
    }
    .ProjectItem_contactImg__1Jnph {
        width: 30px;
        height: 30px;
        margin-bottom: 2px;
    }
}
.About_container__29rDK {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
}

.About_primary__zuAhm {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    max-width: 80vw;
    margin: 5px;
}

.About_left__3veVL {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45%;
}

.About_right__qX_md {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 55%;
}

.About_headline__2FZRr, .About_tag__4Mpnk, .About_title__1lY6x, .About_tagline__Qt-DA {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 80px;
    color: hsl(0, 0%, 20%);
    margin: 10px;
}

.About_headline__2FZRr {
    line-height: 1;
    margin-bottom: 25px;
}

.About_tag__4Mpnk {
    line-height: 1;
    font-size: 30px;
    margin-bottom: 10px;
    min-width: 250px;
}

.About_tagline__Qt-DA {
    font-size: 16px;
    font-weight: 400;
    max-width: 400px;
    min-width: 250px;
    line-height: 1.5;
    margin-bottom: 15px;
}

.About_title__1lY6x {
    font-size: 20px;
}


.About_languageBox__2rVoO {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 620px;
}

.About_contactBox__4W-5y {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.About_iconBox__bG9HZ {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid hsl(0, 0%, 90%);
    border-radius: 8px;
    margin: 10px;
}

.About_links__30X8b {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    padding: 5px;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
    color: hsl(0, 0%, 40%);
}

.About_links__30X8b:hover {
    color: hsl(0, 0%, 15%);
}

.About_iconText__11fNP {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    color: hsl(0, 0%, 40%);
}


.About_iconImg__1FzeV {
    width: 65px;
    height: 65px;
    margin-bottom: 5px;
}

.About_contactImg__uOR4K {
    width: 45px;
    height: 45px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 1100px) {
    .About_primary__zuAhm {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .About_left__3veVL {
        align-items: center;
        text-align: center;
    }
    .About_headline__2FZRr {
        font-size: 60px;
    }
    .About_tag__4Mpnk {
        font-size: 22px;
    }
}

@media only screen and (max-width: 880px) {
    .About_headline__2FZRr {
        font-size: 32px;
    }
    .About_tag__4Mpnk {
        font-size: 18px;
    }
    .About_title__1lY6x {
        font-size: 16px;
        text-align: center;
    }
    .About_tagline__Qt-DA {
        font-size: 13px;
    }
    .About_iconBox__bG9HZ {
        margin: 5px;
        padding: 5px;
    }
    .About_iconImg__1FzeV {
        height: 50px;
        width: 50px;
    }
    .About_iconText__11fNP {
        display: none;
    }
}
.LanguageIcon_iconBox__T6eg3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid hsl(0, 0%, 90%);
    border-radius: 8px;
    margin: 10px;
}

.LanguageIcon_iconText__2kG4- {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    color: hsl(0, 0%, 40%);
}

.LanguageIcon_iconImg__ec-bH {
    width: 65px;
    height: 65px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 880px) {
    .LanguageIcon_iconBox__T6eg3 {
        margin: 5px;
        padding: 5px;
    }
    .LanguageIcon_iconImg__ec-bH {
        height: 50px;
        width: 50px;
    }
    .LanguageIcon_iconText__2kG4- {
        display: none;
    }
}
