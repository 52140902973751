@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Open+Sans:wght@400;700&display=swap');

.iconBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid hsl(0, 0%, 90%);
    border-radius: 8px;
    margin: 10px;
}

.iconText {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    color: hsl(0, 0%, 40%);
}

.iconImg {
    width: 65px;
    height: 65px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 880px) {
    .iconBox {
        margin: 5px;
        padding: 5px;
    }
    .iconImg {
        height: 50px;
        width: 50px;
    }
    .iconText {
        display: none;
    }
}