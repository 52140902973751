@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Open+Sans:wght@400;700&display=swap');

.container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    background-color: hsl(0, 0%, 100%);
    height: 70px;
    padding: 10px;
    border-bottom: 1px solid hsl(0, 0%, 70%);
    box-shadow: 0 1px 5px hsla(0, 0%, 70%, 0.5);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.left, .right {
    width: 50%;
    max-width: 650px;
}

.right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
}

.logo {
    height: 70px;
    width: 70px;
    margin-left: 10px;
}

.navLink {
    text-decoration: none;
    font-family: 'Nunito';
    font-weight: 700;
    font-size: 22px;
    margin: 10px;
    color: hsl(0, 0%, 40%);
    border-bottom: 2px solid hsl(0, 0%, 100%);
}

.navLink:hover {
    color: hsl(0, 0%, 10%);
}

.activeLink {
    color: hsl(0, 0%, 10%);
    border-bottom: 2px solid hsl(0, 0%, 10%);
}

@media only screen and (max-width: 880px) {
    .container {
        height: 40px;
    }
    .logo {
        height: 40px;
        width: 40px;
    }
    .navLink {
        font-size: 16px;
    }
}