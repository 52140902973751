.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.main {
    width: 100vw;
}

.routes {
    position: absolute;
    z-index: 3;
    top: 110px;
}

.overlay {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 100%, 0.85);
}

.background {
    filter: blur(2px);
    min-height: 100%;
    min-width: 1024px;
    height: auto;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
}

@media only screen and (max-width: 880px) {
    .routes {
        top: 80px;
    }
}