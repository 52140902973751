@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Open+Sans:wght@400;700&display=swap');

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    margin-bottom: 30px;
}

.headline {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 15px;
}

.projectBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80vw;
}

@media only screen and (max-width: 880px) {
    .headline {
        font-size: 16px;
    }
    .container {
        margin-bottom: 15px;
    }
}